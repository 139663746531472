var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ab-dropdown",class:{
    'ab-dropdown--floating': !_vm.showInput,
    'ab-dropdown--disabled': _vm.disabled,
  },attrs:{"tabindex":_vm.realTabindex},on:{"focusin":function($event){return _vm.show()},"focusout":function($event){return _vm.focusout($event)}}},[(_vm.showInput)?_c('a',{staticClass:"ab-dropdown__selected",on:{"click":function($event){return _vm.show()}}},[(_vm.hasValue())?[_vm._t("value",function(){return [(_vm.multiple)?[_c('span',{staticClass:"ab-dropdown__selected-text"},[_vm._v(_vm._s(_vm.selectedName.join(', ')))])]:[(_vm.selectedIcon)?_c('i',{staticClass:"ab-dropdown__selected-icon",class:_vm.selectedIcon}):_vm._e(),_vm._v(" "),(_vm.selectedImage)?_c('img',{staticClass:"ab-dropdown__selected-image",attrs:{"src":_vm.selectedImage}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"ab-dropdown__selected-text"},[_vm._v(_vm._s(_vm.selectedName))])]]})]:[_vm._t("defaultValue",function(){return [_c('span',{staticClass:"ab-dropdown__selected-placeholder"},[_vm._v(_vm._s(_vm.placeholder ? _vm.placeholder : _vm.$t('action.makeChoice')))])]})],_vm._v(" "),_c('i',{staticClass:"ab-dropdown__toggle-icon iconoir-nav-arrow-down"})],2):_vm._e(),_vm._v(" "),_c('div',{ref:"itemsContainer",staticClass:"ab-dropdown__items",class:{
      hidden: !_vm.open,
      'ab-dropdown__items--fixed': _vm.fixedItemsImmutable,
      'ab-dropdown__items--max-width': _vm.maxWidth,
    }},[(_vm.showSearch)?_c('div',{staticClass:"select__search"},[_c('i',{staticClass:"select__search-icon iconoir-search"}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],ref:"search",staticClass:"select__search-input",attrs:{"type":"text","placeholder":_vm.searchText === null ? _vm.$t('action.search') : _vm.searchText,"tabindex":"0"},domProps:{"value":(_vm.query)},on:{"keyup":function($event){return _vm.search(_vm.query)},"input":function($event){if($event.target.composing)return;_vm.query=$event.target.value}}})]):_vm._e(),_vm._v(" "),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasDropdownItem),expression:"hasDropdownItem"},{name:"auto-overflow-scroll",rawName:"v-auto-overflow-scroll"}],ref:"items",staticClass:"select__items",class:{ 'select__items--no-max-height': _vm.fixedItemsImmutable },attrs:{"tabindex":"-1"},on:{"scroll":function($event){return _vm.$emit('scroll', $event)}}},[_vm._t("default")],2),_vm._v(" "),(!_vm.hasDropdownItem)?_c('div',{staticClass:"select__items--empty"},[_vm._t("emptyState",function(){return [_vm._v("\n        "+_vm._s(_vm.$t('dropdown.empty'))+"\n      ")]})],2):_vm._e(),_vm._v(" "),(_vm.showFooter)?_c('div',{staticClass:"select__footer"},[_vm._t("footer")],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }