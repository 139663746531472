var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();}}},[_c('CustomStyle',{attrs:{"style-key":"input","config-block-types":['input'],"theme":_vm.builder.theme},model:{value:(_vm.values.styles),callback:function ($$v) {_vm.$set(_vm.values, "styles", $$v)},expression:"values.styles"}}),_vm._v(" "),_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"small-label":"","required":"","label":_vm.$t('recordSelectorElementForm.selectRecordsFrom')}},[_c('DataSourceDropdown',{attrs:{"small":"","data-sources":_vm.listDataSources},scopedSlots:_vm._u([{key:"chooseValueState",fn:function(){return [_vm._v("\n        "+_vm._s(_vm.$t('recordSelectorElementForm.noDataSourceMessage'))+"\n      ")]},proxy:true}]),model:{value:(_vm.values.data_source_id),callback:function ($$v) {_vm.$set(_vm.values, "data_source_id", $$v)},expression:"values.data_source_id"}})],1),_vm._v(" "),_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"small-label":"","label":_vm.$t('recordSelectorElementForm.itemsPerPage'),"error-message":_vm.$v.values.items_per_page.$dirty && !_vm.$v.values.items_per_page.required
        ? _vm.$t('error.requiredField')
        : !_vm.$v.values.items_per_page.integer
        ? _vm.$t('error.integerField')
        : !_vm.$v.values.items_per_page.minValue
        ? _vm.$t('error.minValueField', { min: 5 })
        : !_vm.$v.values.items_per_page.maxValue
        ? _vm.$t('error.maxValueField', { max: _vm.maxItemPerPage })
        : ''}},[_c('FormInput',{attrs:{"type":"number","to-value":(value) => parseInt(value),"placeholder":_vm.$t('recordSelectorElementForm.itemsPerPagePlaceholder')},on:{"blur":function($event){return _vm.$v.values.items_per_page.$touch()}},model:{value:(_vm.values.items_per_page),callback:function ($$v) {_vm.$set(_vm.values, "items_per_page", $$v)},expression:"values.items_per_page"}})],1),_vm._v(" "),(_vm.values.data_source_id)?_c('FormGroup',{key:"optionNameSuffix",staticClass:"margin-bottom-2",attrs:{"small-label":"","label":_vm.$t('recordSelectorElementForm.optionNameSuffix'),"helper-text":_vm.$t('recordSelectorElementForm.optionNameSuffixHelper'),"required":""}},[_c('InjectedFormulaInput',{attrs:{"placeholder":_vm.$t('recordSelectorElementForm.optionNameSuffixPlaceholder'),"application-context-additions":{
        allowSameElement: true,
      }},model:{value:(_vm.values.option_name_suffix),callback:function ($$v) {_vm.$set(_vm.values, "option_name_suffix", $$v)},expression:"values.option_name_suffix"}})],1):_vm._e(),_vm._v(" "),_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"small-label":"","label":_vm.$t('generalForm.labelTitle'),"required":""}},[_c('InjectedFormulaInput',{attrs:{"placeholder":_vm.$t('generalForm.labelPlaceholder')},model:{value:(_vm.values.label),callback:function ($$v) {_vm.$set(_vm.values, "label", $$v)},expression:"values.label"}})],1),_vm._v(" "),(_vm.values.data_source_id)?_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"small-label":"","label":_vm.$t('generalForm.valueTitle'),"required":""}},[_c('InjectedFormulaInput',{attrs:{"placeholder":_vm.$t('generalForm.valuePlaceholder')},model:{value:(_vm.values.default_value),callback:function ($$v) {_vm.$set(_vm.values, "default_value", $$v)},expression:"values.default_value"}})],1):_vm._e(),_vm._v(" "),_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"small-label":"","label":_vm.$t('generalForm.placeholderTitle'),"required":""}},[_c('InjectedFormulaInput',{attrs:{"placeholder":_vm.$t('generalForm.placeholderPlaceholder')},model:{value:(_vm.values.placeholder),callback:function ($$v) {_vm.$set(_vm.values, "placeholder", $$v)},expression:"values.placeholder"}})],1),_vm._v(" "),_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"small-label":"","required":"","label":_vm.$t('recordSelectorElementForm.multipleLabel')}},[_c('Checkbox',{model:{value:(_vm.values.multiple),callback:function ($$v) {_vm.$set(_vm.values, "multiple", $$v)},expression:"values.multiple"}})],1),_vm._v(" "),_c('FormGroup',{staticClass:"margin-bottom-2",attrs:{"small-label":"","required":"","label":_vm.$t('generalForm.requiredTitle')}},[_c('Checkbox',{model:{value:(_vm.values.required),callback:function ($$v) {_vm.$set(_vm.values, "required", $$v)},expression:"values.required"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }